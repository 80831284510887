import React from 'react';
import Home from '../HOme/Home'
import Footer from '../Foooter/Footer'
import MainContentPrivacy from './MainContent';
const PrivacyPolicy = () => {
  return (
<div>
<Home/>
<MainContentPrivacy/>
<Footer/>

</div>
    
    
  );
};

export default PrivacyPolicy;
